import consumer from "./consumer"


consumer.subscriptions.create("PanicsChannel", {
  connected() {
    console.log("Registered")
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {


    // console.log("Recieving:");
    // var panicTemplate = document.getElementById('panic_template');
    // var content = panicTemplate.children().clone(true, true);
      let table = document.getElementById('xx');
      // console.log(table);
      // console.log(data);
    var row = table.insertRow(1);
    var cell1 = row.insertCell(0);
    var cell2 = row.insertCell(1);
    var cell3 = row.insertCell(2);
    var cell4 = row.insertCell(3);
    var cell5 = row.insertCell(4);
    var cell6 = row.insertCell(5);
    var cell7 = row.insertCell(6);

     // let a = data.panic_type.title;
     cell1.innerHTML =   "<a href='panic_requests/" + data['panic']['id'] +  "' class='text-blue-600 no-underline hover:text-primary'>" + data['panic_type']['data']['title'] + "</a>";
     cell2.innerHTML = data['user']['name'] + " " + data['user']['surname'];
     cell3.innerHTML = data['user']['profile']['mobile_no'];
     cell4.innerHTML = "<p class ='text-red-500' >" + data['panic']['data']['state'] + "</p>";
     cell5.innerHTML = data['panic']['data']['latitude'] + " " + data['panic']['data']['longitude'];
     cell6.innerHTML = data['panic']['created_at'];
     cell7.innerHTML = data['panic']['updated_at'];
     play_single_sound();
  }
});

import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = [ "comments" ]


    connect() {
        this.initChannel();
    }

    initChannel() {

        var cable = ActionCable.createConsumer();
        cable.subscriptions.create("PanicsChannel", {
            connected() {
                console.log("it is connected");
                // Called when the subscription is ready for use on the server
            },

            disconnected() {
                // Called when the subscription has been terminated by the server
            },

            received(data) {

                console.log("Recieving:")

            }
        })
    }
}
